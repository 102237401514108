.places-list {
  padding: 1.5rem;
  background-color: #1a1a1a;
  border-radius: 12px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
}

.places-list-header {
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
}

.header-title {
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.places-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); // Default for larger screens
  grid-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns for mobile screens
  }
}

.place-item {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
}

.place-image {
  height: 200px; // Adjusted for mobile screens

  @media (max-width: 768px) {
    height: 150px; // Smaller height for mobile screens
  }
}

.place-details {
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
  color: #333;
}

.load-more-btn {
  display: block;
  margin: 0 auto 20px; // Center button and add spacing at the bottom
  padding: 0.5rem 2rem;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

.marker-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  background: #2a2a2a;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.marker-legend img {
  width: 24px;
  height: 24px;
}

.marker-legend p {
  margin: 0;
  color: #fff;
  font-weight: 500;
}

.no-data {
  text-align: center;
  color: #aaa;
}

.table-dark thead th {
  border-bottom: 3px solid #333;
}

.table-dark tbody tr:hover {
  background-color: #2a2a2a;
}
