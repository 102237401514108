// .transportationPage {
//   padding: 20px;
//   color: white;

//   h1 {
//     display: flex;
//     justify-content: center;
//   }
// }

// .transportationContainer {
//   display: flex;
//   flex-direction: column; // Default to column layout for mobile

//   .subwaysMapClass,
//   .subwaysTableClass {
//     flex: 1; // Allow each child to take equal space
//     margin-bottom: 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .subwaysMapClass .subwaysMapImage {
//     max-width: 100%;
//     max-height: 100%; // Limit height to the container's height
//     width: auto; // Adjust width automatically
//     height: auto;
//     object-fit: contain;
//   }

//   @media (min-width: 881px) {
//     // Adjust for larger screens
//     flex-direction: row; // Side by side layout for web

//     .subwaysMapClass,
//     .subwaysTableClass {
//       margin-bottom: 0;
//       margin-right: 20px;
//       &:last-child {
//         margin-right: 0;
//       }
//       height: 500px; // Fixed height for consistency
//       flex-basis: 0; // Allow flex items to grow and shrink
//       flex-grow: 1; // Flex items will grow to fill the space
//       max-width: 50%; // Each child takes up to 50% of the container width
//     }
//   }
// }

.transportationPage {
  padding: 20px;
  color: white;

  h1 {
    display: flex;
    justify-content: center;
  }
}

.transportationContainer {
  display: flex;
  flex-direction: column; // Default to column layout for mobile
  align-items: center; // Center-align children to match widths better

  .subwaysMapClass,
  .subwaysTableClass {
    width: 100%; // Make both elements take full width of their container
    max-height: 400px; // Adjust this to set a max height for the container
    overflow: hidden; // Hide overflow to maintain the size
    margin-bottom: 20px; // Space between map and table
  }

  img {
    display: block; // Ensure the image is block level to fill width
    max-width: 100%; // Limit image width to not exceed its container
    height: auto; // Adjust height automatically to maintain aspect ratio
    object-fit: cover; // Cover the area without distorting the image
  }

  table {
    width: 100%; // Ensure table takes full width of its parent
    table-layout: auto; // Allows table cells to adjust based on content
    border-collapse: collapse; // Collapses border to remove space between them

    th,
    td {
      text-align: left; // Align text to the left for headers and cells
      padding: 8px; // Adjust padding for content inside cells
      border-right: none; // Remove vertical lines by not setting right borders
    }

    th:last-child,
    td:last-child {
      border-right: 0; // Ensure the last column doesn't have a right border
    }
  }
}

@media (min-width: 881px) {
  .transportationContainer {
    flex-direction: row; // Side by side layout for larger screens
    justify-content: space-between; // Distribute space evenly between elements
    align-items: flex-start; // Align items at the start of the container

    .subwaysMapClass,
    .subwaysTableClass {
      flex-basis: calc(50% - 10px); // Adjust basis for even distribution
      height: auto; // Allow height to adjust based on content
      margin-bottom: 0; // Remove bottom margin when side by side
    }
  }
}
