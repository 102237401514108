// .listingBanner {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 30px;
//   background-color: whitesmoke;
//   z-index: 500;

//   div {
//     @media (min-width: 370px) {
//       span {
//         font-size: 16x;
//       }
//     }

//     @media (max-width: 370px) {
//       span {
//         font-size: 14px;
//       }
//     }
//   }
// }

// .toolbar_button {
//   font-size: 12;
//   align-self: center;
// }

// .switch_container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   span {
//     text-align: center;
//     color: whitesmoke;
//   }
// }

// .rowOne {
//   flex: 1;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: flex-start;
// }

// .placeTypeSuggestion {
//   display: block;
//   position: relative;
// }

// #business-type-suggestions {
//   position: absolute;
//   z-index: 1;
//   margin-left: calc(92% - 250px);
//   background: rgba(0, 35, 75, 97%);
//   color: #fff;
//   padding-bottom: 10px;

//   span {
//     padding: 1px 18px;
//     cursor: pointer;
//   }

//   span:hover {
//     text-decoration: underline;
//   }
// }

// @media (min-width: 551px) {
//   .places-list_header .places-list_header {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-around;
//     align-items: center;
//     background-color: rgba(0, 123, 255, 0.25);
//     height: 75px;
//     border-radius: 0.25rem;
//     padding: 10px;
//     overflow: visible;
//   }

//   .place_dropdown_container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     div {
//       span {
//         color: whitesmoke;
//       }
//     }
//   }

//   .fade_container {
//     top: 22.5%;
//     right: 26%;
//     width: 150px;
//     position: absolute;
//     padding-top: 1em;
//     text-align: center;
//     background-color: rgba(238, 238, 238, 0.55);

//     span {
//       color: black;
//     }
//   }

//   .streetView_container {
//     background-color: #eeeeee;
//     width: 30%;
//     height: 20%;
//     position: absolute;
//     top: 155px;
//     left: 25.35%;
//     z-index: 100;
//   }
// }

// .googleMap_container {
//   width: 100%;
//   height: 100%;
// }

// .extrasContainer {
//   width: 100%;
//   height: 100%;
// }

// .map-control_bar {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   background-color: rgba(0, 123, 255, 0.25);
//   height: 90%;
//   border-radius: 0.25rem;
//   padding: 10px;
//   overflow: visible;
// }

// .place_dropdown_container {
//   display: flex;
//   flex-direction: row;
//   align-items: center;

//   div {
//     span {
//       color: whitesmoke;
//     }
//   }
// }

.listingBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: whitesmoke;
  z-index: 500;

  div {
    @media (min-width: 370px) {
      span {
        font-size: 16px; // Corrected typo from 16x to 16px
      }
    }

    @media (max-width: 370px) {
      span {
        font-size: 14px;
      }
    }
  }
}

.toolbar_button {
  background-color: #006455;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
}

.switch_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
    color: #ecf0f1;
  }
}

.rowOne {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.placeTypeSuggestion {
  display: block;
  position: relative;

  &:hover {
    background-color: #34495e;
    color: #ecf0f1;
  }
}

#business-type-suggestions {
  position: absolute;
  z-index: 1050;
  margin-left: calc(92% - 250px);
  background: white; /* Light background for contrast */
  color: #000; /* Dark text for readability */
  max-height: 500px; /* Fixed height with scrolling */
  overflow-y: auto; /* Enable scrolling for overflow */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 0; /* Vertical padding */
}

#business-type-suggestions span {
  display: block;
  padding: 8px 18px;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

#business-type-suggestions span:hover,
#business-type-suggestions span:focus {
  background-color: #006455; /* Highlight on hover/focus */
  outline: none; /* Remove outline for focused items */
}

@media (min-width: 551px) {
  .places-list_header .places-list_header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 123, 255, 0.25);
    height: 75px;
    border-radius: 0.25rem;
    padding: 10px;
    overflow: visible;
  }

  .toolbar_button,
  .place_dropdown_container > Button {
    font-size: 16px; // Adjust for desktop
  }
}

.googleMap_container {
  width: 100%;
  height: 100%;
}

.extrasContainer {
  width: 100%;
  height: 100%;
}

.map-control_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 40%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 90%;
  border-radius: 0.25rem;
  // padding: 10px;
  overflow: visible;
  margin-bottom: 8px;
}

.place_dropdown_container {
  display: flex;
  flex-direction: column; // Align children vertically
  align-items: start; // Align items to the start of the container
  gap: 10px; // Adjust the gap between label and search bar as needed

  span {
    color: white; // Ensure the label text is visible
  }

  .search_and_load {
    display: flex;
    flex-direction: row; // Align children horizontally
    align-items: center; // Align items vertically in the center
    gap: 5px; // Adds a small gap between the search bar and the button

    .large_input {
      flex-grow: 1; // Allows the input to grow and fill available space
      margin-right: 5px; // Ensures a margin to the right of the input if needed
    }
  }
}

// Additional styles for a professional look
.map-buttons {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto; // Changed from 100% to auto for better control
  background-color: #2c3e50; // Darker shade for a more professional look
  padding: 10px;
  opacity: 0.9; // Slightly increased for better readability
  display: flex;
  justify-content: center;
  gap: 10px; // Increased gap for better spacing
  border-radius: 5px; // Added rounded corners
}

@media (max-width: 550px) {
  .map-buttons {
    flex-direction: column; // Stack buttons vertically on smaller screens
    gap: 5px; // Reduce gap for compact layout
  }
}
