// @import "variables";

// .App {
//   // text-align: -webkit-center;
//   // display: flex;
//   // align-content: center;
//   // align-items: center;
//   // justify-content: center;
//   max-width: 1440px;
// }

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 40vmin;
//   pointer-events: none;
// }
// html,
// body {
//   min-height: 100vh;
//   margin: 0;
//   position: relative;
//   overflow-x: hidden;
// }

// // #footer change modify
// // body::after {
// //   content: '';
// //   display: block;
// //   height: 50px; /* Set same as footer's height */
// // }

// .App-main {
//   max-width: 1440px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .App-header {
//   background-image: url(../images/backgrounds/NYC_contrast.jpg);
//   color: black;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   // height: 100%;
//   min-height: 60vh;
//   // align-content: center;
//   align-items: center;
//   // font-size: calc(10px + 2vmin);
//   // color: rgb(0, 0, 0);
//   // background-repeat: no-repeat;
//   // border-radius: 10px;
//   // padding: 20px;
// }

// .disclaimerContainer {
//   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
//   font-weight: bold;
//   color: black;
// }

// .fullLogoContainer {
//   height: 80%;
//   transform: translate(0, -25%);
// }

// // .lookupContainer {
// //   height: 100%;
// //   display: flex;
// //   flex-direction: column;
// // }

// .logoTitleContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   height: 200px;
// }

// .titlesContainer {
//   display: flex;
//   flex-direction: column;
//   white-space: nowrap;
//   text-align: left;
// }

// .titleLogo {
//   @media (max-width: 550px) {
//     width: 300px;
//     height: 90px;
//   }
// }

// .logoContainer {
//   height: 100px;
// }

// .homePageTitle {
//   font-family: "Arial Black";
//   font-size: 56px;
// }

// .homePageSubTitle {
//   font-family: "Arial Bold";
//   font-size: 22px;
// }
// .results-container {
//   display: flex;
//   flex-direction: row;
//   // height: 72.2vh; /* Adjust the height as needed */
// }

// .results-container > div {
//   flex: 1;
//   /* Optional: If you want to add some space between the divs */
//   margin: 0 5px;
// }

// // .results-container {
// //   height: "100%";
// //   display: flex;
// // }

// // @media (min-width: $md-pt) {
// //   .map_container {
// //     height: 100%;
// //     width: 100%;
// //     border: 1px solid rgb(62, 68, 74);
// //   }
// // }
// // @media (max-width: $md-pt) {
// //   .map_container {
// //     height: 100%;
// //     width: 100%;
// //     border: 1px solid rgb(62, 68, 74);
// //   }
// // }

// .map-control_button {
//   height: 7vh;
//   width: 10%;
// }

// // @media (min-width: $md-pt) {
// //   .places-list {
// //     width: 100%;
// //     height: 100%;
// //     display: flex;
// //     flex-direction: column;
// //     //  min-height: 80vh;
// //     background: rgb(31, 33, 48);
// //   }
// //   .no-data {
// //     text-align: center;
// //   }

// //   .demographics_container {
// //     width: 100%;
// //     height: 100%;
// //     display: flex;
// //     flex-direction: column;
// //     //  min-height: 80vh;
// //     background: rgb(0, 0, 0);
// //   }
// // }

// // @media (max-width: $md-pt) {
// //   .places-list {
// //     width: 100%;
// //     height: calc(100vh - 32px);
// //     display: flex;
// //     flex-direction: column;
// //     background: rgb(26, 28, 41);
// //   }

// //   .demographics_container {
// //     width: 100%;
// //     height: calc(100vh - 32px);
// //     display: flex;
// //     flex-direction: column;
// //     background: rgb(0, 0, 0);
// //   }
// // }

// // .demographics-list-vertical {
// //   width: 25%;
// //   height: 80vh;
// //   float: left;
// //   background: rgb(206, 206, 206);
// //   overflow-x: auto;
// //   font-size: 8;
// //   border: 1px solid rgb(62, 68, 74);
// // }

// // .demographics-list-horizontal {
// //   width: 100vh;
// //   height: 40vh;
// //   margin: auto;
// //   background: rgb(206, 206, 206);
// //   overflow-x: auto;
// // }

// // .demographics-list_table {
// //   width: 100%;
// //   height: 80vh;
// // }

// // .demographics_list_row {
// //   background: rgb(180, 172, 172);
// //   text-align: center;
// // }

// // .demographicsWrappper {
// //   width: 100%;
// //   height: 100%;
// // }

// // .placesListWrapper {
// //   width: 100%;
// //   height: 100%;
// // }

// // Parent div
// .parentDiv {
//   display: flex;
//   width: 100%;
// }

// .demographicsWrappper,
// .placesListWrapper {
//   flex: 1; // This ensures that each takes up 50% of the space
// }

// // Existing styles adjustments
// @media (min-width: $md-pt) {
//   .demographics_container,
//   .places-list {
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     background: transparent;
//   }
// }

// @media (max-width: $md-pt) {
//   .demographics_container,
//   .places-list {
//     height: calc(100vh - 32px);
//     display: flex;
//     flex-direction: column;
//     background: transparent;
//   }
// }

html {
  font-family: "Clarkson", Helvetica, sans-serif;
}

.app-container {
  display: flex;
  height: 100vh; /* Default height */
}

@media (max-width: 550px) {
  .app-container {
    height: 150vh !important; /* Mobile override */
  }
}
.map-container {
  flex: 1;
  // Add any additional map styling here
}

.right-container {
  flex: 1;
  overflow-y: auto;
  // Add any additional right side styling here
}

// Add this media query for mobile devices
@media (max-width: 550px) {
  .app-container {
    flex-direction: column;
  }

  .map-container,
  .right-container {
    flex: none; // This overrides the flex: 1 for mobile devices
    width: 100%; // Make sure they take the full width
  }
}
